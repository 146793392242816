/**
 * Defines custom render components for each Rich Text element.
 */

import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Options } from "@contentful/rich-text-react-renderer"
import Image from "./Image"
import Heading from "./Heading"
import { Link } from "gatsby"

export const richTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content.length === 1 && (node.content[0] as any).value === "") {
        return <br></br>
      }
      return <p>{children}</p>
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <Heading className="text-6xl" El="h1">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Heading className="text-5xl" El="h2">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Heading className="text-4xl" El="h3">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Heading className="text-3xl" El="h4">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Heading className="text-2xl" El="h5">
        {children}
      </Heading>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Heading className="text-xl" El="h6">
        {children}
      </Heading>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc ml-4">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal ml-4">{children}</ol>
    ),
    [BLOCKS.QUOTE]: (node, children) => {
      return (
        <blockquote className="bg-gray-300 px-4 border-primary-900 border-l-4 py-2">
          {children}
        </blockquote>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return <Image node={node as any} />
    },
    [INLINES.HYPERLINK]: (node, children) => {
      if ((node.data.uri as string).startsWith("/")) {
        return (
          <Link to={node.data.uri} className="link">
            {children}
          </Link>
        )
      }
      return (
        <a className="link" href={node.data.uri}>
          {children}
        </a>
      )
    },
  },
}
