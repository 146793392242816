import React from "react"

type Props = {
  children: React.ReactNode
  className?: string
}
const Pill: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={`bg-primary-700 hover:bg-primary-900 rounded-full text-white px-4 py-1 focus:outline-none ${className}`}
    >
      {children}
    </div>
  )
}

export default Pill
