import React from "react"
import { Document } from "@contentful/rich-text-types"
import { richTextOptions } from "./RichText/index"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, Link } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Pill from "../../components/Pill/index"
import Title from "../../components/Title/index"
import SEO from "../../components/Seo"

type Props = {
  pageContext: {
    title: string
  }
  data: {
    contentfulBlogPost: {
      date: string
      title: string
      tags: {
        name: string
        slug: string
      }[]
      body: {
        json: Document
      }
      image: {
        fluid: FluidObject
      }
      brief: {
        brief: string
      }
    }
    site: {
      siteMetadata: {
        prefixes: {
          category: string
        }
      }
    }
  }
}
const BlogLayout: React.FC<Props> = ({ data }) => {
  return (
    <div className="text-gray-700">
      <SEO
        title={data.contentfulBlogPost.title}
        description={data.contentfulBlogPost.brief.brief}
        image={
          data.contentfulBlogPost.image
            ? data.contentfulBlogPost.image.fluid
            : undefined
        }
      />
      <div className="mb-4">
        <Title className="mb-1">{data.contentfulBlogPost.title}</Title>
        <p className="text-sm">{data.contentfulBlogPost.date}</p>
      </div>
      {data.contentfulBlogPost.image && (
        <a
          href={data.contentfulBlogPost.image.fluid.src}
          target="_blank"
          rel="noopener noreferrer"
          className="h-64 md:h-96 w-full bg-red-400"
        >
          <Img
            fluid={data.contentfulBlogPost.image.fluid}
            className="h-64 md:h-96 mb-4"
            imgStyle={{ objectFit: "cover" }}
          />
        </a>
      )}
      {documentToReactComponents(
        data.contentfulBlogPost.body.json,
        richTextOptions
      )}
      <hr className="my-3 border-gray-500" />
      <div>
        <span>Categorías:</span>
        <div className="flex flex-wrap">
          {data.contentfulBlogPost.tags.map((tag, i) => (
            <Link
              to={`/${data.site.siteMetadata.prefixes.category}/${tag.slug}`}
              className="mr-1 lg:mr-2 my-2"
              key={i}
            >
              <Pill className="text-sm">{tag.name}</Pill>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query($slug: String!, $dateFormat: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      date(formatString: $dateFormat, locale: "es-ES")
      tags {
        name
        slug
      }
      body {
        json
      }
      image {
        fluid(maxWidth: 1500, maxHeight: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      brief {
        brief
      }
    }
    site {
      siteMetadata {
        prefixes {
          category
        }
      }
    }
  }
`
export default BlogLayout
