import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

type Props = {
  node: {
    data: {
      target: {
        sys: {
          contentful_id: string
        }
      }
    }
  }
}

const Image: React.FC<Props> = ({ node }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            contentful_id
            fluid(maxWidth: 1500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)

  const img_id: string = node.data.target.sys.contentful_id

  type Edge = {
    node: {
      contentful_id: string
    }
  }
  const img: {
    node: { fluid: FluidObject }
  } = data.allContentfulAsset.edges.find((edge: Edge) => {
    return edge.node.contentful_id === img_id
  })

  if (img) {
    return (
      <a
        href={img.node.fluid.src}
        target="_blank"
        rel="noopener noreferrer"
        className="h-64 lg:h-96"
      >
        <Img
          fluid={img.node.fluid}
          className="h-64 lg:h-96"
          imgStyle={{ objectFit: "contain" }}
        />
      </a>
    )
  } else {
    return <></>
  }
}

export default Image
