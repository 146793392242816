import React from "react"

type Props = {
  El: any
  className: string
  children: React.ReactNode
}
const Heading: React.FC<Props> = ({ El, className, children }) => {
  return <El className={`leading-tight mb-2 ${className}`}>{children}</El>
}

export default Heading
